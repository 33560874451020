


























































import DraftActions from '@/builder/mixins/DraftActions'
import { DraftActionsType } from '@/calendesk/models/DraftActionsType'
import mixins from 'vue-typed-mixins'
import { mapActions, mapGetters } from 'vuex'
import SelectableCategory from '@/calendesk/models/SelectableCategory'
import SelectableService from '@/calendesk/models/SelectableService'
import SectionConfigurationService from '@/calendesk/models/SectionConfigurationService'
import Category from '@/calendesk/models/DTO/Response/Category'
import duration from '@/lib/calendesk-js-library/filters/duration'
export default mixins(DraftActions).extend({
  name: 'ServicesSelect',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      selectedEmployees: Array<string>(),
      mode: DraftActionsType.SECTION,
      loading: false,
      items: Array<SelectableCategory>(),
      panel: 0
    }
  },
  computed: {
    ...mapGetters({
      selectedSection: 'builder/getSelectedSection',
      categoryAndServices: 'category/categoryWithServices'
    }),
    selectedActionList () {
      return [
        { title: { single: 'edit_service', many: '' }, target: 'service' },
        { title: { single: 'remove_service', many: '' }, target: 'service', class: 'accent_light--text' }
      ]
    }
  },
  watch: {
    data () {
      this.reloadItems()
    }
  },
  mounted () {
    if (!this.categoryAndServices) {
      this.loading = true
      this.fetchCategoryAndServices()
        .then(() => {
          this.reloadItems()
        }).finally(() => { this.loading = false })
    } else {
      this.reloadItems()
    }
  },
  methods: {
    ...mapActions({
      fetchCategoryAndServices: 'category/fetchCategoryAndServices'
    }),
    getDuration (value: number) {
      return duration(value)
    },
    reloadServices () {
      this.loading = true
      this.fetchCategoryAndServices().finally(() => {
        this.reloadItems()
        this.loading = false
      })
    },
    selectAllServicesToggle (selectableCategory: SelectableCategory) {
      selectableCategory.selectableServices.forEach((selectableService: SelectableService) => {
        selectableService.selected = selectableCategory.selected
      })

      this.updateSelectedServices(selectableCategory)
    },
    updateSelectedServices (selectableCategory: SelectableCategory): void {
      const selectedServices: SectionConfigurationService[] = []
      let allServicesSelected = true

      this.items.forEach((selectableCategory: SelectableCategory) => {
        selectableCategory.selectableServices.forEach((selectableService: SelectableService) => {
          if (selectableService.selected) {
            selectedServices.push({ id: selectableService.service.id })
          } else {
            allServicesSelected = false
          }
        })
      })

      selectableCategory.selected = allServicesSelected

      this.updateConfigurationProperty(this.data.key, selectedServices)
    },
    reloadItems (): void {
      const selectedServiceIds = this.selectedSection.configuration[this.data.key].map((item: SectionConfigurationService) => item.id)
      this.items = []

      if (this.categoryAndServices) {
        this.categoryAndServices.forEach((category: Category) => {
          const selectableCategory = new SelectableCategory(false, category)
          let allServicesSelected = true

          selectableCategory.selectableServices.forEach((selectableService: SelectableService) => {
            if (selectedServiceIds.includes(selectableService.service.id)) {
              selectableService.selected = true
            } else {
              allServicesSelected = false
            }
          })

          selectableCategory.selected = selectableCategory.selectableServices.length > 0 ? allServicesSelected : false

          this.items.push(selectableCategory)
        })
      }
    }
  }
})

import Category from '@/calendesk/models/DTO/Response/Category'
import SelectableService from '@/calendesk/models/SelectableService'
import Service from '@/calendesk/models/DTO/Response/Service'

export default class SelectableCategory {
  selected: boolean;
  category: Category
  selectableServices: SelectableService[]

  constructor (selected: boolean, category: Category) {
    this.selected = selected
    this.category = category
    const selectableServices = category.services?.map((service: Service) => {
      return new SelectableService(selected, service)
    })

    if (selectableServices) {
      this.selectableServices = selectableServices
    } else {
      this.selectableServices = []
    }
  }
}

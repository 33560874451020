import Service from '@/calendesk/models/DTO/Response/Service'

export default class SelectableService {
  selected: boolean;
  service: Service

  constructor (selected: boolean, service: Service) {
    this.selected = selected
    this.service = service
  }
}
